<template>
  <div>
    <div class="card height-auto v-application--is-ltr text-left">
      <div class="card-body">
        <v-data-table
          :options.sync="options"
          :loading="loading"
          loading-text="Loading... Please wait"
          v-model="selected"
          :headers="headers"
          :items="items"
          item-key="id"
          :show-select="false"
          class="elevation-1 level1 templates"
          text="left"
          hide-default-footer
        >
          <template v-slot:top>
            <v-row class="headerTable-div">
              <v-col md="4" sm="12">
                <h3 class="custom-header main-color">
                  {{ $t("Account Statement") }}
                </h3>
              </v-col>
              <v-col md="4" sm="12">
                <label class="search" for="inpt_search">
                  <input
                    id="inpt_search"
                    class="input_search"
                    @focus="inputFocus"
                    @blur="inputBlur"
                    type="text"
                    @keyup.enter="getAllData(page)"
                    v-model.trim="search"
                  />

                  <v-icon
                    :title="$t('Search')"
                    @click="getAllData(page)"
                    class="search-icon"
                    >search</v-icon
                  >
                </label>
              </v-col>

              <v-col md="4" sm="12" class="text-right">
                <span title="Filter">
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="300"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <i
                        v-on="on"
                        class="fas fa-filter main-color fa-lg"
                        :title="$t('Filter')"
                      ></i>
                    </template>

                    <v-list>
                      <v-list-item class="select">
                        <v-autocomplete
                          v-model="filter.grades"
                          :label="$t('Grades')"
                          :items="grades"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item class="select">
                        <v-autocomplete
                          v-model="filter.types"
                          :label="$t('Type')"
                          :items="types"
                          @change="hideLabel = true"
                          item-text="text"
                          item-value="id"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <v-card-actions> </v-card-actions>
                    </v-list>
                  </v-menu>
                </span>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.code="{ item }">
            <router-link :to="'/AccountStatements/' + item.code">
              {{ item.code }}
            </router-link>
          </template>
        </v-data-table>
        <!-- ======================================== Pagination ======================================  -->
        <div class="text-center pt-2">
          <v-pagination
            v-if="length > 0 && totalItems > 0"
            v-model="page"
            :length="length"
            :circle="circle"
            :page="page"
            :total-visible="totalVisible"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
          <v-snackbar
            v-model="snack"
            :timeout="snackTime"
            :color="snackColor"
            :right="true"
            :top="true"
          >
            <!-- {{ snackText }} -->

            <span class="white--text">{{ snackText }}</span>

            <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
          </v-snackbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ACL from "../../acl";
export default {
  name: "AccountStatement",
  data() {
    return {
      options: {
        itemsPerPage: 15,
      },
      snack: "",
      // types: [],
      dialogReset: false,
      snackTime: 3000,
      snackColor: "",
      snackText: "",
      filterMenu: false,
      loading: true,
      formTitle: "",
      selected: [],
      headers: [
        {
          text: this.$i18n.t("Partner Code"),
          value: "code",
          sortable: true,
        },
        {
          text: this.$i18n.t("Partner Name"),
          value: "name",
          sortable: true,
        },
        {
          text: this.$i18n.t("Type"),
          value: "type",
          sortable: true,
        },
        {
          text: this.$i18n.t("Grade"),
          value: "grade",
          sortable: true,
        },
      ],
      title: this.$i18n.t("Account Statement"),
      apiEndPoints: {
        list: "account-statement",
      },
      permissions: {
        list: ACL.checkPermission("list-account-statements"),
      },

      items: [],
      filter: {
        types: [],
        grades: [],
      },
      search: "",
      circle: true,
      page: 1,
      length: "",
      totalVisible: "",
      totalItems: "",
      grades: [],
      types: [
        { id: "student", text: this.$i18n.t("Student") },
        { id: "applicant", text: this.$i18n.t("Applicant") },
      ],
    };
  },
  watch: {
    "$route.query": {
      handler() {
        if (this.$route.query.page !== undefined) {
          this.page = Number(this.$route.query.page);
          this.getAllData(this.$route.query.page);
        } else {
          this.getAllData(1);
        }
      },
      deep: true,
    },
    page: function () {
      this.getAllData();
    },

    // search: {
    //   handler() {
    //     this.page = 1;
    //     this.getAllData(this.page);
    //   },
    //   deep: true,
    // },
    filter: {
      handler() {
        this.page = 1;
        this.getAllData(this.page);
      },
      deep: true,
    },
  },
  methods: {
    inputFocus() {
      $("#inpt_search").parent("label").addClass("active");
      $(".input_search").parent("label").addClass("active");
    },
    inputBlur() {
      if (this.search == "") {
        $("#inpt_search").parent("label").removeClass("active");
        $(".input_search").parent("label").removeClass("active");
      }
    },

    getAllData(page = null) {
      if (page == null) {
        page = this.page;
      }
      this.items = [];
      this.loading = true;
      axios
        .get(
          this.getApiUrl +
            "/account-statement?search=" +
            this.search +
            "&page=" +
            page,
          {
            params: {
              filter: this.filter,
            },
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.setPaginationParameters(response);
          this.totalItems = response.data.data.total;
          this.items = [];
          this.items = response.data.data.data;
          this.loading = false;
        });
    },
    setPaginationParameters(response) {
      this.length = response.data.data.last_page;
      if (this.length > 5) this.totalVisible = 5;
    },
    getGrades() {
      // api/homework/getGrade
      axios
        .get(this.getApiUrl + "/account-statement/getGrade", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.setPaginationParameters(response);

          this.grades = response.data.data;
        });
    },
  },
  mounted() {
    this.getAllData(this.page);
    this.getGrades();
  },
};
</script>

<style scoped></style>
